var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    {
      attrs: {
        title: _vm.$t(
          _vm.isEdit
            ? "lbl_edit_qr"
            : _vm.isViewFromGR
            ? "lbl_view_qr"
            : "lbl_create_qr"
        )
      }
    },
    [
      _c(
        "a-form-model",
        _vm._b(
          {
            ref: "formQr",
            attrs: {
              model: _vm.formModel,
              "label-align": "left",
              rules: _vm.formRules
            }
          },
          "a-form-model",
          _vm.formWrapper,
          false
        ),
        [
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: _vm.$t(_vm.formProp.barcodeNumber.label),
                name: _vm.formProp.barcodeNumber.name,
                prop: "barcodeNumber"
              }
            },
            [
              _c(
                "a-input-search",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    name: _vm.formProp.barcodeNumber.name,
                    placeholder: _vm.$t(_vm.formProp.barcodeNumber.placeholder),
                    "allow-clear": "",
                    disabled: !_vm.isUnused
                  },
                  on: { search: _vm.findBarcode },
                  model: {
                    value: _vm.formModel.barcodeNumber,
                    callback: function($$v) {
                      _vm.$set(_vm.formModel, "barcodeNumber", $$v)
                    },
                    expression: "formModel.barcodeNumber"
                  }
                },
                [
                  _c(
                    "a-button",
                    {
                      attrs: {
                        slot: "enterButton",
                        type: "primary",
                        disabled:
                          !_vm.formModel.barcodeNumber ||
                          (_vm.isEdit && _vm.isViewFromGR) ||
                          !_vm.isUnused,
                        loading: _vm.loader.find
                      },
                      slot: "enterButton"
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_generate")) + " ")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: _vm.$t(_vm.formProp.itemCode.label),
                name: _vm.formProp.itemCode.name
              }
            },
            [
              _c(
                "a-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    name: _vm.formProp.itemCode.name,
                    placeholder: _vm.$t(_vm.formProp.itemCode.placeholder),
                    disabled: !_vm.isUnused,
                    "allow-clear": "",
                    "show-search": "",
                    "filter-option": false,
                    loading: _vm.loader.itemCode
                  },
                  on: {
                    search: _vm.fillOptItemCodes,
                    change: function($event) {
                      return _vm.findProductByItemCode(_vm.formModel.itemCode)
                    }
                  },
                  model: {
                    value: _vm.formModel.itemCode,
                    callback: function($$v) {
                      _vm.$set(_vm.formModel, "itemCode", $$v)
                    },
                    expression: "formModel.itemCode"
                  }
                },
                _vm._l(_vm.dtItemCodes.data, function(item, index) {
                  return _c(
                    "a-select-option",
                    { key: index, attrs: { value: item.code } },
                    [_vm._v(" " + _vm._s(item.code) + " ")]
                  )
                }),
                1
              )
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: _vm.$t(_vm.formProp.productCode.label),
                name: _vm.formProp.productCode.name,
                prop: "productCode"
              }
            },
            [
              _c(
                "a-select",
                {
                  attrs: {
                    placeholder: _vm.$t(_vm.formProp.productCode.placeholder),
                    name: _vm.formProp.productCode.name,
                    "allow-clear": true,
                    "filter-option": false,
                    "show-search": "",
                    disabled: !_vm.isUnused
                  },
                  on: {
                    search: _vm.getListProduct,
                    select: _vm.onselectProductCode
                  },
                  model: {
                    value: _vm.formModel.productCode,
                    callback: function($$v) {
                      _vm.$set(_vm.formModel, "productCode", $$v)
                    },
                    expression: "formModel.productCode"
                  }
                },
                _vm._l(_vm.dtProducts.data, function(item) {
                  return _c(
                    "a-select-option",
                    { key: item.id, attrs: { value: item.code } },
                    [
                      _c(
                        "a-tooltip",
                        [
                          _c("template", { slot: "title" }, [
                            _vm._v(" " + _vm._s(item.code || "-") + " ")
                          ]),
                          _vm._v(" " + _vm._s(item.code || "-") + " ")
                        ],
                        2
                      )
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: _vm.$t(_vm.formProp.productName.label),
                name: _vm.formProp.productName.name,
                prop: "productName"
              }
            },
            [
              _c("span", { staticClass: "ant-form-text" }, [
                _vm._v(_vm._s(_vm.formModel.productName || "-"))
              ])
            ]
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: _vm.$t(_vm.formProp.uom.label),
                name: _vm.formProp.uom.name,
                prop: "uom"
              }
            },
            [
              _c(
                "a-select",
                {
                  attrs: {
                    placeholder: _vm.$t(_vm.formProp.uom.placeholder),
                    name: _vm.formProp.uom.name,
                    "allow-clear": true,
                    disabled: !_vm.isUnused
                  },
                  model: {
                    value: _vm.formModel.uom,
                    callback: function($$v) {
                      _vm.$set(_vm.formModel, "uom", $$v)
                    },
                    expression: "formModel.uom"
                  }
                },
                _vm._l(_vm.optUom, function(item) {
                  return _c(
                    "a-select-option",
                    { key: item.id, attrs: { value: item.id } },
                    [
                      _c(
                        "a-tooltip",
                        [
                          _c("template", { slot: "title" }, [
                            _vm._v(" " + _vm._s(item.label || "-") + " ")
                          ]),
                          _vm._v(" " + _vm._s(item.label || "-") + " ")
                        ],
                        2
                      )
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: _vm.$t(_vm.formProp.qty.label),
                name: _vm.formProp.qty.name,
                prop: "qty"
              }
            },
            [
              _c("a-input-number", {
                staticStyle: { width: "100%" },
                attrs: {
                  name: _vm.formProp.qty.name,
                  placeholder: _vm.$t(_vm.formProp.qty.placeholder),
                  disabled: !_vm.isUnused
                },
                model: {
                  value: _vm.formModel.qty,
                  callback: function($$v) {
                    _vm.$set(_vm.formModel, "qty", _vm._n($$v))
                  },
                  expression: "formModel.qty"
                }
              })
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: _vm.$t(_vm.formProp.packDate.label),
                name: _vm.formProp.packDate.name,
                prop: "packDate"
              }
            },
            [
              _c("a-date-picker", {
                staticStyle: { width: "100%" },
                attrs: {
                  placeholder: _vm.$t(_vm.formProp.packDate.placeholder),
                  name: _vm.formProp.packDate.name,
                  format: _vm.DEFAULT_DATE_FORMAT,
                  disabled: !_vm.isUnused
                },
                model: {
                  value: _vm.formModel.packDate,
                  callback: function($$v) {
                    _vm.$set(_vm.formModel, "packDate", $$v)
                  },
                  expression: "formModel.packDate"
                }
              })
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: _vm.$t(_vm.formProp.supplierName.label),
                name: _vm.formProp.supplierName.name
              }
            },
            [
              _c("span", { staticClass: "ant-form-text" }, [
                _vm._v(_vm._s(_vm.formModel.supplierName || "-"))
              ])
            ]
          ),
          _vm.isEdit || _vm.isViewFromGR
            ? _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: _vm.$t(_vm.formProp.batchNumber.label),
                    name: _vm.formProp.batchNumber.name
                  }
                },
                [
                  _c("span", { staticClass: "ant-form-text" }, [
                    _vm._v(_vm._s(_vm.formModel.batchNumber || "-"))
                  ])
                ]
              )
            : _vm._e(),
          _vm.isEdit || _vm.isViewFromGR
            ? _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: _vm.$t(_vm.formProp.status.label),
                    name: _vm.formProp.status.name
                  }
                },
                [
                  _c("span", { staticClass: "ant-form-text" }, [
                    _vm._v(_vm._s(_vm.formModel.status || "-"))
                  ])
                ]
              )
            : _vm._e(),
          _vm.isEdit || _vm.isViewFromGR
            ? _c(
                "a-form-model-item",
                { attrs: { label: _vm.$t(_vm.formProp.qrcode.label) } },
                [
                  _c(
                    "a-space",
                    { attrs: { direction: "vertical", align: "center" } },
                    [
                      _c("QrCode", {
                        attrs: {
                          value: _vm.formModel.batchNumber,
                          quality: 1,
                          scale: 2,
                          size: 250,
                          "white-margin": false
                        },
                        on: { onCallback: _vm.onCallbackQr }
                      }),
                      !_vm.isViewFromGR
                        ? _c(
                            "a-button",
                            {
                              attrs: {
                                icon: "printer",
                                type: "primary",
                                loading: _vm.loader.print
                              },
                              on: { click: _vm.print }
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("lbl_print")) + " ")]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c("a-divider"),
      _c(
        "a-row",
        { attrs: { gutter: [16, 16] } },
        [
          _c(
            "a-col",
            { attrs: { align: "end" } },
            [
              _c(
                "a-space",
                [
                  !_vm.isEdit
                    ? _c("a-button", { on: { click: _vm.resetForm } }, [
                        _vm._v(" " + _vm._s(_vm.$t("lbl_reset")) + " ")
                      ])
                    : _vm._e(),
                  _vm.hasPrivilegeBatchCreate || _vm.hasPrivilegeBatchUpdate
                    ? _c(
                        "a-button",
                        {
                          attrs: {
                            icon: "save",
                            type: "primary",
                            loading: _vm.loader.save,
                            disabled: !_vm.isUnused
                          },
                          on: { click: _vm.handleSave }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_save")) + " ")]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }