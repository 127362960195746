































































































































































































































// core
import Vue from "vue";
import moment from "moment";
import printJS from "print-js";
// services
import localStorageService from "@/services/localStorage.service";
import { batchService } from "@service/batch.service";
import { productService } from "@service/product.service";
import { productItemCodeServices } from "@service/product-item-code.service";
import { contactServices } from "@service/contact.service";
// interface
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { RequestBatchSupplierCreate } from "@interface/batch.interface";
import { ResponseDetailProduct, ResponseListProduct } from "@interface/product.interface";
import { UomConversions } from "@interface/uom.interface";
import { IAuthorities } from "@interface/auth.interface";
import { ResponseListProductItemCode } from "@interface/product-item-code.interface";
import { ResponseListContactData } from "@interface/contact.interface";
// constant
import { DEFAULT_DATE_FORMAT } from "@constant/date.constant";
import { Mode } from "@enum/global.enum";
import { BATCH_STATUS } from "@enum/batch.enum";
// utility
import { letterKeydown } from "@/validator/globalvalidator";
import { debounceProcess } from "@helper/debounce";
import MNotificationVue from "@/mixins/MNotification.vue";
import { DEFAULT_PAGE_SIZE } from "@/models/constant/global.constant";
import { INITIAL_PROCESS } from "@/models/constant/qr.constant";
import { FormModel } from "ant-design-vue";

export default Vue.extend({
  name: "FormQr",
  mixins: [
    MNotificationVue
  ],
  props: {
    showButtonClose: {
      type: Boolean,
      default: true,
      required: false
    },
    /**
     * override back option if
     * this component triggered from
     * modal
     */
    overrideBack: {
      type: Boolean,
      default: false,
      required: false
    },
    propRowBatchSecureId: {
      type: String,
      default: "",
      required: false
    }
  },
  data() {
    this.fillOptItemCodes = debounceProcess(this.fillOptItemCodes, 300);
    this.getListProduct = debounceProcess(this.getListProduct, 300);
    this.searchSupplierName = debounceProcess(this.searchSupplierName, 300);
    return {
      DEFAULT_DATE_FORMAT,
      formModel: {
        barcodeNumber: "",
        productCode: undefined as string | undefined,
        productName: "",
        productId: "",
        qty: 0,
        packDate: "",
        batchNumber: "",
        itemCode: undefined as string | undefined,
        ctnSerialNumber: "",
        uom: undefined as string | undefined,
        status: "",
        supplierId: "",
        supplierName: ""
      },
      formProp: {
        barcodeNumber: {
          label: "lbl_barcode_number",
          name: "barcodeNumber",
          placeholder: "lbl_type_here"
        },
        supplierName: {
          label: "lbl_supplier_name",
          name: "supplierName",
          placeholder: "lbl_choose"
        },
        productCode: {
          label: "lbl_product_code",
          name: "productCode",
          placeholder: "lbl_type_here"
        },
        productName: {
          label: "lbl_product_name",
          name: "productName",
          placeholder: ""
        },
        qty: {
          label: "lbl_qty",
          name: "qty",
          placeholder: "lbl_type_here"
        },
        packDate: {
          label: "lbl_pack_date",
          name: "packDate",
          placeholder: "lbl_choose"
        },
        batchNumber: {
          label: "lbl_batch_number",
          name: "batchNumber",
          placeholder: ""
        },
        qrcode: {
          label: "lbl_qrcode"
        },
        itemCode: {
          label: "lbl_item_code",
          name: "itemCode",
          placeholder: "lbl_type_here"
        },
        uom: {
          label: "lbl_uom",
          name: "uom",
          placeholder: "lbl_type_here"
        },
        status: {
          label: "lbl_status",
          name: "status",
          placeholder: "lbl_type_here"
        }
      },
      formRules: {
        barcodeNumber: [
          {
            required: false,
            message: () => this.$t("lbl_validation_required_error"),
            trigger: "change"
          },
          {
            min: 44,
            max: 44,
            message: () => this.$t("lbl_validation_min_max_length", {length: 44})
          }
        ],
        productCode: [
          {
            required: true,
            message: () => this.$t("lbl_validation_required_error"),
            trigger: "change"
          }
        ],
        productName: [
          {
            required: false,
            message: () => this.$t("lbl_validation_required_error"),
            trigger: "change"
          }
        ],
        qty: [
          {
            required: true,
            message: () => this.$t("lbl_validation_required_error"),
            trigger: "change"
          }
        ],
        packDate: [
          {
            required: true,
            message: () => this.$t("lbl_validation_required_error"),
            trigger: "change"
          }
        ],
        uom: [
          {
            required: true,
            message: () => this.$t("lbl_validation_required_error"),
            trigger: "change"
          }
        ],
      },
      dtProducts: {} as ResponseListProduct,
      loader: {
        save: false,
        find: false,
        itemCode: false,
        print: false,
        supplierName: false
      },
      batchId: "",
      dataUrlQr: "",
      optUom: [] as { label: string, id: string }[],
      optSupplierName: [] as { key: string, value: string }[],
      userPrivileges: [] as IAuthorities[],
      dtItemCodes: {} as ResponseListProductItemCode
    };
  },
  computed: {
    formWrapper() {
      return {
        labelCol: {
          sm: {
            span: 24
          },
          md: {
            span: 7
          },
          lg: {
            span: 6
          }
        },
        wrapperCol: {
          sm: {
            span: 24
          },
          md: {
            span: 14
          },
          lg: {
            span: 12
          }
        }
      };
    },
    isEdit(): boolean {
      return this.$route.meta.mode === Mode.EDIT;
    },
    isViewFromGR(): boolean {
      return this.propRowBatchSecureId !== "";
    },
    hasPrivilegeBatch(): IAuthorities | undefined {
      return this.userPrivileges.find(x => x.key === "batch");
    },
    hasPrivilegeBatchCreate(): boolean {
      return this.hasPrivilegeBatch?.privilege.create || false;
    },
    hasPrivilegeBatchUpdate(): boolean {
      return this.hasPrivilegeBatch?.privilege.update || false;
    },
    isUsed(): boolean {
      return this.formModel.status === BATCH_STATUS.USED;
    },
    isProcessed(): boolean {
      return this.formModel.status === BATCH_STATUS.PROCESSED;
    },
    isUnused(): boolean {
      return this.formModel.status ? this.formModel.status === BATCH_STATUS.UNUSED : true;
    },
  },
  created() {
    this.loadUserPrivileges();
    this.getListProduct();
    this.fillOptItemCodes();
    this.searchSupplierName();
    if (this.$route.meta.mode === Mode.EDIT) {
      this.batchId = this.$route.params.id;
      this.getDetailBatch(this.batchId);
    } else if (this.propRowBatchSecureId) {
      this.batchId = this.propRowBatchSecureId;
      this.getDetailBatch(this.batchId);
    }
  },
  activated() {
    if (this.propRowBatchSecureId && !this.loader.save) {
      this.batchId = this.propRowBatchSecureId;
      this.getDetailBatch(this.batchId);
    }
  },
  deactivated() {
    this.resetForm();
  },
  methods: {
    letterKeydown,
    moment,
    getPrintFile(batchNumber: string): Promise<ArrayBuffer> {
      return batchService.getPrintFile(batchNumber);
    },
    getLisItemCodes(param: RequestQueryParamsModel): Promise<ResponseListProductItemCode> {
      return productItemCodeServices.getListItemCodes(param);
    },
    getProductByItemCode(itemCode: string): Promise<ResponseDetailProduct> {
      return productItemCodeServices.getProductByItemCode(itemCode);
    },
    getListSupplierName(params: RequestQueryParamsModel): Promise<ResponseListContactData> {
      return contactServices.listContactData(params);
    },
    loadUserPrivileges(): void {
      const privileges = localStorageService.loadUserPrivilege();
      this.userPrivileges = privileges;
    },
    async searchSupplierName(search = ""): Promise<void> {
      try {
        this.loader.supplierName = true;
        const params: RequestQueryParamsModel = {
          page: 0,
          limit: DEFAULT_PAGE_SIZE,
          sorts: "firstName:asc",
          search: "supplier~true_AND_active~true"
        };
        if (search) params.search = `supplier~true_AND_active~true_AND_firstName~*${search}*`;
        const { data } = await this.getListSupplierName(params);
        this.optSupplierName = data.map(x => { return { key: x.fullName, value: x.id }; });
      } catch (error) {
        this.showErrorMessage("notif_process_fail");
      } finally {
        this.loader.supplierName = false;
      }
    },
    getListProduct(search = ""): void {
      const param: RequestQueryParamsModel = {
        limit: DEFAULT_PAGE_SIZE,
        page: 0,
        sorts: "createdDate:desc",
        search: "active~true"
      };

      if (search) param.search = `name~*${search}*_OR_code~*${search}*_AND_active~true`;

      productService.listProduct(param)
      .then((res: ResponseListProduct) => {
        this.dtProducts = res;
      });
    },
    onselectProductCode(): void {
      const prod = this.dtProducts.data.find(el => el.code === this.formModel.productCode);
      this.formModel.productId = prod?.id || "";
      this.formModel.productName = prod?.name || "";
      this.getDetailProduct(this.formModel.productId);
    },
    createNewBatch(req: RequestBatchSupplierCreate): void {
      batchService.createBatchSupplier(req)
      .then(() => {
        this.showNotifSuccess("notif_create_success");
        this.resetForm();
      })
      .finally(() => {
        this.loader.save = false;
      });
    },
    updateBatch(batchId: string, req: RequestBatchSupplierCreate): void {
      batchService.updateBatchSupplier(batchId, req)
      .then(() => {
        this.showNotifSuccess("notif_update_success");
      })
      .finally(() => {
        this.loader.save = false;
      });
    },
    handleSave(): void {
      const { formModel } = this;
      const req: RequestBatchSupplierCreate = {
        barcodeNumber: formModel.barcodeNumber,
        batchNumber: formModel.batchNumber,
        initialProcess: INITIAL_PROCESS,
        packDate: this.moment(formModel.packDate).format(),
        productId: formModel.productId,
        qty: formModel.qty,
        ctnSerialNumber: formModel.ctnSerialNumber,
        grChecklistNumber: "",
        status: BATCH_STATUS.UNUSED,
        uomId: formModel.uom || "",
        itemCode: formModel.itemCode || null,
      };
      const ref = this.$refs.formQr as FormModel;
      ref.validate(valid => {
        if (valid) {
          this.loader.save = true;
          if (this.isEdit) {
            this.updateBatch(this.batchId, req);
          } else {
            this.createNewBatch(req);
          }
        }
      });
    },
    getDetailBatch(batchId: string): void {
      this.loader.save = true;
      batchService.viewBatch(batchId)
      .then(res => {
        this.formModel = {
          barcodeNumber: res.barcodeNumber,
          productCode: res.productCode,
          productName: res.productName,
          qty: res.qty,
          packDate: res.packDate,
          productId: res.productId,
          batchNumber: res.batchNumber,
          itemCode: res.itemCode,
          ctnSerialNumber: res.ctnSerialNumber,
          uom: res.baseUnitId,
          status: res.status,
          supplierId: res.supplierId,
          supplierName: res.supplierName
        };
        this.optUom = [];
        if (res.uomConversions && res.uomConversions.length) {
          res.uomConversions.forEach(item => this.optUom.push({ label: item.unitUom, id: item.unitUomId }));
        } else {
          this.optUom.push({ label: res.baseUnit, id: res.baseUnitId });
        }
        const supp = this.optSupplierName.find(x => x.value === this.formModel.supplierId);
        if (!supp) {
          this.optSupplierName.push({ key: this.formModel.supplierName, value: this.formModel.supplierId });
        }
      })
      .finally(() => this.loader.save = false);
    },
    resetForm(): void {
      this.formModel = {
        barcodeNumber: "",
        productCode: "",
        productName: "",
        productId: "",
        qty: 0,
        packDate: "",
        batchNumber: "",
        itemCode: "",
        ctnSerialNumber: "",
        uom: "",
        status: "",
        supplierId: "",
        supplierName: ""
      };
    },
    onCallbackQr(dataUrl): void {
      this.dataUrlQr = dataUrl;
    },
    async print(): Promise<void> {
      try {
        this.loader.print = true;
        const { batchNumber } = this.formModel;
        const file = await this.getPrintFile(batchNumber);
        const pdf = window.URL.createObjectURL(new Blob([file]));
        printJS({
          printable: pdf,
          onError: (error) => {
            this.$notification.error({
              message: this.$t("lbl_error_title").toString(),
              description: error.message
            });
          }
        });
      } catch (error) {
        this.showNotifError("notif_process_fail");
      } finally {
        this.loader.print = false;
      }
    },
    findBarcode(): void {
      if (!this.formModel.barcodeNumber) return;
      this.loader.find = true;
      batchService.findByBarcode(this.formModel.barcodeNumber)
      .then(res => {
        let form = {
          barcodeNumber: this.formModel.barcodeNumber,
          batchNumber: res.batchNumber,
          productCode: res.productCode,
          productName: res.productName,
          productId: res.productId,
          qty: res.qty,
          packDate: res.packDate,
          itemCode: res.itemCode,
          ctnSerialNumber: res.ctnSerialNumber,
          uom: res.baseUnitId,
          status: res.status,
          supplierName: res.supplierName,
          supplierId: res.supplierId
        };
        this.fillUom(res.uomConversions);
        this.formModel = form;
      })
      .catch(() => {
        this.showNotifError("notif_data_not_found");
      })
      .finally(() => {
        this.loader.find = false;
      });
    },
    async getDetailProduct(productId: string): Promise<void> {
      try {
        this.optUom = [];
        const res = await productService.detailProduct(productId);
        this.formModel.uom = res.baseUnitId;
        this.formModel.supplierId = res.supplierId;
        this.formModel.supplierName = res.supplierFullName;
        this.fillUom(res.uomConversions);
      } catch (error) {
        this.showNotifError("notif_data_not_found");
      }
    },
    fillUom(data: UomConversions[]): void {
      this.optUom = [];
      data.forEach(item => {
        this.optUom.push({
          label: item.unitUom,
          id: item.unitUomId
        });
      });
    },
    async fillOptItemCodes(search = ""): Promise<void> {
      try {
        const param: RequestQueryParamsModel = {
          limit: DEFAULT_PAGE_SIZE,
          page: 0,
          sorts: "createdDate:desc"
        };
        this.loader.itemCode = true;
        if (search) param.search = `code~*${search}*`;
        const res = await this.getLisItemCodes(param);
        this.dtItemCodes = res;
      } catch (error) {
        this.showNotifError("notif_data_not_found");
      } finally {
        this.loader.itemCode = false;
      }
    },
    async findProductByItemCode(itemCode: string | undefined): Promise<void> {
      try {
        if (!itemCode) return;
        const product = await this.getProductByItemCode(itemCode);
        const model = {
          productCode: product.code,
          productName: product.name,
          productId: product.id,
          itemCode: itemCode,
          uom: product.baseUnitId,
          supplierId: product.supplierId,
          supplierName: product.supplierFullName
        };
        this.formModel = {...this.formModel, ...model};
        this.fillUom(product.uomConversions);
      } catch (error) {
        this.showNotifError("notif_data_not_found");
      }
    }
  }
});
