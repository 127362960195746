import { Api } from "@/models/class/api.class";
import { ResponseListProductItemCode } from "@/models/interface-v2/product-item-code.interface";
import { ResponseDetailProduct } from "@/models/interface-v2/product.interface";
import { ErrorResponseModel, RequestQueryParamsModel } from "@/models/interface/http.interface";
import { HttpClient } from "@/services/http.service";
import { AxiosError } from "axios";

export class ProductItemCodeServices extends HttpClient {
  constructor() {
    super()
  }

  public getListItemCodes(params: RequestQueryParamsModel): Promise<ResponseListProductItemCode> {
    return this.get<ResponseListProductItemCode>(Api.ProductItemCode, { params })
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error)
    })
  }

  getProductByItemCode(itemCode: string): Promise<ResponseDetailProduct> {
    return this.get<ResponseDetailProduct>(`${Api.ProductItemCode}/${itemCode}`)
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error)
    })
  }
}

export const productItemCodeServices = new ProductItemCodeServices()
